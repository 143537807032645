import sift from 'sift';
import { Message } from './types';

export function parseQuery(queryString: string): any {
    if (!queryString.trim()) {
        return {}; // Return an empty object for empty queries
    }

    const tokens = queryString.match(/\[.*?\]|\(|\)|\w+|"[^"]*"|'[^']*'|>=|<=|!=|=|>|<|IN|CONTAINS|IS EMPTY|LENGTH|AND|OR|NOT/g) || [];
    const output: any[] = [];
    const operators: string[] = [];

    const precedence: { [key: string]: number } = {
        'NOT': 3,
        'AND': 2,
        'OR': 1,
        '(': 0
    };

    function applyOperator() {
        const operator = operators.pop();
        if (operator === 'AND') {
            const right = output.pop();
            const left = output.pop();
            output.push({ $and: [left, right] });
        } else if (operator === 'OR') {
            const right = output.pop();
            const left = output.pop();
            output.push({ $or: [left, right] });
        } else if (operator === 'NOT') {
            const operand = output.pop();
            output.push({ $not: operand });
        }
    }

    function parseArray(arrayString: string): any[] {
        return JSON.parse(arrayString.replace(/'/g, '"'));
    }

    for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i];
        if (token === '(') {
            operators.push(token);
        } else if (token === ')') {
            while (operators.length && operators[operators.length - 1] !== '(') {
                applyOperator();
            }
            operators.pop(); // Remove the '('
        } else if (token === 'AND' || token === 'OR' || token === 'NOT') {
            while (operators.length && precedence[operators[operators.length - 1]] >= precedence[token]) {
                applyOperator();
            }
            operators.push(token);
        } else {
            // It's a field comparison
            const field = token;
            let op = tokens[++i];

            // Handle multi-word operators
            if (op === 'IS' && tokens[i + 1] === 'EMPTY') {
                op = 'IS EMPTY';
                i += 1;
            }

            const value = i + 1 < tokens.length ? tokens[++i] : null;

            let condition: any = {};
            if (value) {
                switch (op) {
                    case '=': condition[field] = value.replace(/^['"]|['"]$/g, ''); break;
                    case '!=': condition[field] = { $ne: value.replace(/^['"]|['"]$/g, '') }; break;
                    case '>': condition[field] = { $gt: parseFloat(value) }; break;
                    case '<': condition[field] = { $lt: parseFloat(value) }; break;
                    case '>=': condition[field] = { $gte: parseFloat(value) }; break;
                    case '<=': condition[field] = { $lte: parseFloat(value) }; break;
                    case 'CONTAINS':
                        condition[field] = { $regex: value.replace(/^['"]|['"]$/g, ''), $options: 'i' };
                        break;
                    case 'IN':
                        condition[field] = { $in: parseArray(value) };
                        break;
                    case 'LENGTH':
                        condition[field] = { $size: parseInt(value) };
                        break;
                }
            } else {
                switch (op) {
                    case 'IS EMPTY':
                        condition = {
                            $or: [
                                { [field]: { $exists: false } },
                                { [field]: { $size: 0 } },
                                { [field]: null }
                            ]
                        };
                        i--; // No value to consume
                        break;
                }
            }
            output.push(condition);
        }
    }

    while (operators.length) {
        applyOperator();
    }

    return output[0] || {};
}

export function filterMessages(messages: Message[], query: string, queryType: string): Message[] {
    if (query.trim() === "") return messages;
    let parsedQuery;
    if (queryType === "simple") {
        // simple queries need to be processed into mongo query format
        parsedQuery = parseQuery(query);
        if (!parsedQuery) {
            console.log("No query, return all messages");
            return messages;
        }
    } else if (queryType === "mongo") {
        // we alerady have a mongo query
        try {
            parsedQuery = JSON.parse(query);
        } catch (e) {
            console.error("Invalid mongo query:", query);
            console.log("Attempting to treat it as a javascript object literal");
            try {
                parsedQuery = eval(`(${query})`);
            } catch (e) {
                console.error("Invalid javascript object literal query:", query);
                return messages;
            }
        }
    } else {
        console.error("Invalid query type:", queryType);
        return messages;
    }
    console.log("Parsed Query:", JSON.stringify(parsedQuery, null, 2));
    const results = messages.filter(sift(parsedQuery));
    console.log("Filtered Results:", results);
    return results;
}

// Example usage
// const data = [
//     { name: "John Doe", age: 30, status: "active" },
//     { name: "Jane Smith", age: 25, status: "pending" },
//     { name: "Bob Johnson", age: 45, status: "active" },
// ];

// const userQuery = '(age >= 18 AND age <= 65) AND (status = "active" OR status = "pending")';
// const parsedQuery = parseQuery(userQuery);
// console.log("Parsed Query:", JSON.stringify(parsedQuery, null, 2));

// const results = data.filter(sift(parsedQuery));
// console.log("Filtered Results:", results);