import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-606b4f96"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "api-key-alert"
}
const _hoisted_2 = {
  class: "filter-container",
  ref: "filterContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageFilter = _resolveComponent("MessageFilter")!
  const _component_ChatContent = _resolveComponent("ChatContent")!
  const _component_TagEditorModal = _resolveComponent("TagEditorModal")!
  const _component_CorporateAIEditorModal = _resolveComponent("CorporateAIEditorModal")!
  const _component_SettingsModal = _resolveComponent("SettingsModal")!
  const _component_FilesModal = _resolveComponent("FilesModal")!

  return (_openBlock(), _createElementBlock("div", {
    id: "dev-chat",
    class: _normalizeClass({ 'sidebar-open': _ctx.isMenuOpen }),
    onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleOutsideClick && _ctx.handleOutsideClick(...args)))
  }, [
    (_ctx.showTokenAlert)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " OpenAI token is not set in settings. Please set it to use the chat functionality. "))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "menu-toggle",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)), ["stop"]))
    }, "☰"),
    _createElementVNode("div", {
      class: _normalizeClass(["sidebar", { 'open': _ctx.isMenuOpen }]),
      onClick: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.editTags && _ctx.editTags(...args)))
      }, "Edit Tags"),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.editCorporateAI && _ctx.editCorporateAI(...args)))
      }, "Edit Corporate AI"),
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.openSettings && _ctx.openSettings(...args)))
      }, "Settings"),
      _createElementVNode("button", {
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.openFilesModal && _ctx.openFilesModal(...args)))
      }, "Manage Files"),
      _createElementVNode("button", {
        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args)))
      }, "Reset Chat"),
      _createElementVNode("button", {
        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.toggleAudioStreaming && _ctx.toggleAudioStreaming(...args))),
        class: "audio-stream"
      }, _toDisplayString(_ctx.audioManager.streaming ? 'Stop Audio' : 'Start Audio'), 1),
      _createElementVNode("button", {
        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.toggleFilter && _ctx.toggleFilter(...args)))
      }, _toDisplayString(_ctx.showFilter ? 'Hide Filter' : 'Show Filter'), 1)
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showFilter)
        ? (_openBlock(), _createBlock(_component_MessageFilter, {
            key: 0,
            onApplySimpleFilter: _ctx.applySimpleFilter,
            onApplyMongoFilter: _ctx.applyMongoFilter,
            onGenerateFilter: _ctx.handleGenerateFilter
          }, null, 8, ["onApplySimpleFilter", "onApplyMongoFilter", "onGenerateFilter"]))
        : _createCommentVNode("", true)
    ], 512),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredChatStack, (chatMessages, index) => {
      return (_openBlock(), _createBlock(_component_ChatContent, {
        key: index,
        messages: chatMessages,
        isLoading: _ctx.isLoading && index === _ctx.filteredChatStack.length - 1,
        showTriggerSystem2Button: _ctx.showTriggerSystem2Button && index === 0,
        depth: index,
        onTriggerSystem2: _ctx.triggerSystem2,
        onOpenTraceInfo: _ctx.openTraceInfo,
        onClose: ($event: any) => (_ctx.closeTraceInfo(index)),
        onSendMessage: _ctx.sendMessage
      }, null, 8, ["messages", "isLoading", "showTriggerSystem2Button", "depth", "onTriggerSystem2", "onOpenTraceInfo", "onClose", "onSendMessage"]))
    }), 128)),
    (_ctx.isTagEditorOpen)
      ? (_openBlock(), _createBlock(_component_TagEditorModal, {
          key: 1,
          processorService: _ctx.chatService,
          onClose: _ctx.closeTagEditor
        }, null, 8, ["processorService", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.isCorporateAIEditorOpen)
      ? (_openBlock(), _createBlock(_component_CorporateAIEditorModal, {
          key: 2,
          processorService: _ctx.chatService,
          onClose: _ctx.closeCorporateAIEditor
        }, null, 8, ["processorService", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.isSettingsOpen)
      ? (_openBlock(), _createBlock(_component_SettingsModal, {
          key: 3,
          onClose: _ctx.closeSettings,
          onSave: _ctx.saveSettings
        }, null, 8, ["onClose", "onSave"]))
      : _createCommentVNode("", true),
    (_ctx.isFilesModalOpen)
      ? (_openBlock(), _createBlock(_component_FilesModal, {
          key: 4,
          processorService: _ctx.chatService,
          onClose: _ctx.closeFilesModal
        }, null, 8, ["processorService", "onClose"]))
      : _createCommentVNode("", true)
  ], 2))
}