<template>
    <div class="message-filter">
        <h3>Message Filter</h3>
        <div class="tabs">
            <button :class="{ tab: true, active: activeTab === 'generate' }" @click="activeTab = 'generate'">
                Generate Filter Query
            </button>
            <button :class="{ tab: true, active: activeTab === 'filter' }" @click="activeTab = 'filter'">
                Filter Query
            </button>
        </div>
        <div class="tab-content">
            <div v-if="activeTab === 'generate'" class="filter-field">
                <textarea v-model="generateFilterPrompt" placeholder="Describe what the filter you want generated..."
                    @input="autoResize" @keydown="handleKeyDown('generate', $event)"></textarea>
            </div>
            <div v-if="activeTab === 'filter'" class="filter-field">
                <div class="format-options">
                    <label>
                        <input type="radio" v-model="filterFormat" value="simple"> Simple
                    </label>
                    <label>
                        <input type="radio" v-model="filterFormat" value="mongo"> MongoQuery
                    </label>
                </div>
                <textarea v-model="filterPrompt" placeholder="Enter your filter query here..." @input="autoResize"
                    @keydown="handleKeyDown('filter', $event)"></textarea>
            </div>
        </div>
        <div class="action-buttons">
            <button v-if="activeTab === 'generate'" @click="generateFilter" :disabled="isGenerating">
                {{ isGenerating ? 'Generating...' : 'Generate Filter' }}
            </button>
            <button v-if="activeTab === 'filter'" @click="applyFilter">Apply Filter</button>
            <button v-if="activeTab === 'filter'" @click="resetFilter">Reset Filter</button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from 'vue';

export default defineComponent({
    name: 'MessageFilter',
    emits: ['apply-simple-filter', 'apply-mongo-filter', 'generate-filter'],
    setup(_, { emit }) {
        const generateFilterPrompt = ref('');
        const filterPrompt = ref('');
        const activeTab = ref('generate');
        const filterFormat = ref('simple');
        const generateHistory = ref<string[]>([]);
        const filterHistory = ref<string[]>([]);
        const generateHistoryIndex = ref(-1);
        const filterHistoryIndex = ref(-1);
        const isGenerating = ref(false);

        const loadHistory = () => {
            const savedGenerateHistory = localStorage.getItem('generateHistory');
            const savedFilterHistory = localStorage.getItem('filterHistory');
            if (savedGenerateHistory) {
                generateHistory.value = JSON.parse(savedGenerateHistory);
            }
            if (savedFilterHistory) {
                filterHistory.value = JSON.parse(savedFilterHistory);
            }
        };

        const saveHistory = () => {
            localStorage.setItem('generateHistory', JSON.stringify(generateHistory.value));
            localStorage.setItem('filterHistory', JSON.stringify(filterHistory.value));
        };

        const addToHistory = (type: 'generate' | 'filter', item: string) => {
            const history = type === 'generate' ? generateHistory : filterHistory;
            if (item && !history.value.includes(item)) {
                history.value.unshift(item);
                if (history.value.length > 50) {
                    history.value.pop();
                }
                saveHistory();
            }
        };

        const generateFilter = async () => {
            if (generateFilterPrompt.value.trim()) {
                isGenerating.value = true;
                try {
                    await new Promise<void>((resolve) => {
                        emit('generate-filter', generateFilterPrompt.value, (filter: string, filterType: string) => {
                            filterPrompt.value = filter;
                            filterFormat.value = filterType;
                            resolve();
                        });
                    });
                    addToHistory('generate', generateFilterPrompt.value);
                    generateHistoryIndex.value = -1;
                    activeTab.value = 'filter';
                } finally {
                    isGenerating.value = false;
                }
            }
        };

        const applyFilter = () => {
            if (filterPrompt.value.trim()) {
                const eventName = filterFormat.value === 'simple' ? 'apply-simple-filter' : 'apply-mongo-filter';
                emit(eventName, filterPrompt.value);
                addToHistory('filter', filterPrompt.value);
                filterHistoryIndex.value = -1;
            }
        };

        const resetFilter = () => {
            generateFilterPrompt.value = '';
            filterPrompt.value = '';
            emit('apply-mongo-filter', '');
            generateHistoryIndex.value = -1;
            filterHistoryIndex.value = -1;
        };

        const autoResize = (e: Event) => {
            const textarea = e.target as HTMLTextAreaElement;
            textarea.style.height = 'auto';
            textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
        };

        const handleKeyDown = (type: 'generate' | 'filter', e: KeyboardEvent) => {
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
                const history = type === 'generate' ? generateHistory : filterHistory;
                const historyIndex = type === 'generate' ? generateHistoryIndex : filterHistoryIndex;
                const promptRef = type === 'generate' ? generateFilterPrompt : filterPrompt;

                if (e.key === 'ArrowUp') {
                    historyIndex.value = Math.min(historyIndex.value + 1, history.value.length - 1);
                } else {
                    historyIndex.value = Math.max(historyIndex.value - 1, -1);
                }

                if (historyIndex.value >= 0) {
                    promptRef.value = history.value[historyIndex.value];
                } else {
                    promptRef.value = '';
                }
            } else if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                if (type === 'generate') {
                    generateFilter();
                } else {
                    applyFilter();
                }
            }
        };

        onMounted(() => {
            loadHistory();
        });

        watch([generateFilterPrompt, filterPrompt], () => {
            if (generateFilterPrompt.value === '' && filterPrompt.value === '') {
                generateHistoryIndex.value = -1;
                filterHistoryIndex.value = -1;
            }
        });

        return {
            generateFilterPrompt,
            filterPrompt,
            activeTab,
            filterFormat,
            isGenerating,
            generateFilter,
            applyFilter,
            resetFilter,
            autoResize,
            handleKeyDown,
        };
    },
});
</script>

<style scoped>
.message-filter {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tabs {
    display: flex;
    margin-bottom: 0;
    border-bottom: none;
}

.tab {
    padding: 10px 20px;
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    margin-right: 5px;
    transition: all 0.3s ease;
    color: #333;
    font-weight: 500;
}

.tab.active {
    background-color: #fff;
    border-bottom: 1px solid #fff;
    color: #007bff;
    font-weight: 600;
}

.tab-content {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0 8px 8px 8px;
    padding: 20px;
}

textarea {
    width: 100%;
    height: 50px;
    min-height: 50px;
    max-height: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
}

.action-buttons {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

button {
    padding: 8px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-weight: 500;
}

button:hover {
    background-color: #0056b3;
}

button:active {
    transform: translateY(1px);
}

button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.format-options {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

.format-options label {
    display: flex;
    align-items: center;
    gap: 5px;
}
</style>